// custom typefaces
import './src/vendor/fonts/Inter-Regular-slnt=0.ttf'
import './src/vendor/fonts/Nunito-Regular.ttf'

import "prismjs/themes/prism.css"
import "./src/styles/index.css"

import "./node_modules/swiper/swiper-bundle.min.css"
import './node_modules/uikit/dist/js/uikit.min.js'
import './node_modules/uikit/dist/js/uikit-icons.min.js'
import './node_modules/uikit/dist/js/uikit-core.min.js'
import './node_modules/uikit/dist/css/uikit-core.min.css'
import './node_modules/uikit/dist/css/uikit.min.css'